<style lang="scss">
.c-text{
    text-align: left;
}
.o-pocillos-12 {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 5px 10px;
    grid-auto-rows: minmax(60px, auto);
    padding: 0;
  }
</style>
<template>
  <v-layout row justify-center>
      <v-dialog v-model="dialog"
        persistent
      >
          <v-card class="o-modal-muestras">
              <v-card-text>
                <!-- TITULO DE EL MODAL -->
                <v-row >
                    <v-col
                        cols="12"
                        sm="12"
                        class="o-modal-muestras__header"
                    >
                        <span class="headline">La OT
                        <span class="o-modal-muestras__ot">{{OT}}</span>
                        contiene {{OTNSamples}} muestras</span>
                    </v-col>
                </v-row>
                <!-- KIT DE PCR Y DESHACER -->
                <v-row >
                    <v-col
                        cols="12"
                        sm="7"
                    >
                        <v-select
                            v-model="F.dataForm.kitPcr"
                            :items="F.kitPCR"
                            item-text="name"
                            item-value="id"
                            label="KIT DE PCR"
                            placeholder="Seleccionar kit de PCR"
                            dense
                            outlined
                            @change="filtraEnsayos()"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="auto"
                    >
                        <v-btn color="black" dark @click="deshacer()" class="o-refresh">
                        <v-icon class="o-refresh__icon"
                        >mdi-refresh</v-icon>
                        Deshacer
                        </v-btn>
                    </v-col>
                </v-row>
                <!-- TABLA DE MUESTRAS Y POCILLOS -->
                <v-row class="justify-content-space-between" >
                    <!-- TABLA -->
                    <v-col
                        cols="12" sm="3"
                    >
                        <v-simple-table>
                            <template v-slot:default>
                            <caption class="o-modal-muestras__caption">
                                <h3
                                    class="o-modal-muestras__h3 text-center"
                                >Muestras</h3>
                            </caption>
                            <!--<thead>
                                <tr>
                                    <th class="text-left">
                                        <h3
                                            class="o-modal-muestras__h3 text-center"
                                        >Muestras</h3>
                                    </th>
                                </tr>
                            </thead>-->
                            <tbody class="o-modal-muestras__tbody">
                                <tr
                                v-for="item in data.muestras_ots"
                                :key="item.id"
                                >
                                    <td
                                        v-if="item.visible === true"
                                        class="o-modal-muestras__td text-center"
                                        width="80" align="center"
                                    >
                                        <v-checkbox
                                            class="m-auto p-0 text-center"
                                            v-model="item.vmodel"
                                            :label="''+item.id"
                                            color="purple darken-3"
                                            :disabled="item.disabled"
                                            hide-details
                                            @click="clickSample(item)"
                                        ></v-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <!-- POCILLOS -->
                    <v-col cols="12" sm="8" >
                        <v-row class="align-items-center justify-content-justify">
                            <v-col cols="4" sm="6">
                                <h3
                                    class="o-modal-muestras__h3 o-modal-muestras__h3--blue"
                                >Asignar pocillos</h3>
                            </v-col>
                            <v-col cols="4" sm="3">
                                <v-checkbox
                                    v-model="controles.cn"
                                    label="Control Negativo"
                                    color="purple darken-3"
                                    value="CN"
                                    @click="checkCotrol('cn',controles.cn)"
                                    class="m-auto p-0 o-modal-muestras__checkbox"
                                    hide-details
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="4" sm="3">
                                <v-checkbox
                                    v-model="controles.cp"
                                    label="Control Positivo"
                                    color="purple darken-3"
                                    value="CP"
                                    @click="checkCotrol('cp',controles.cp)"
                                    class="m-auto p-0 o-modal-muestras__checkbox"
                                    hide-details
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row >
                            <v-col :class="getEquipo()">
                                <div class="d-pocillo"
                                    v-for="pocillo in pocillos" :key="pocillo.id"
                                >
                                    <v-checkbox
                                        v-model="pocillo.checked"
                                        class="o-pocillos__checkbox"
                                        @click="clickPocillo(pocillo)"
                                    ></v-checkbox>
                                    <div class="v-pocillo">{{pocillo.data || 'N/A'}}</div>
                                    <div class="id-pocillo">
                                      {{pocillo.id}} {{( (pocillo.nameCheck != '')
                                      ?'('+ pocillo.nameCheck+')' : '')}}
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row >
                            <v-col cols="12" class="mt-5">
                                <p class="c-text bold">
                                    No olvides verificar que cada pocilo asignado a tu
                                    muestra esté asociado a su análisis correspondiente,
                                    ni olvides incluir tus controles.
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="black"  @click.native="close()" dark>Cancelar</v-btn>
                  <v-btn color="indigo darken-2"  @click.native="save()" dark>Guardar</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </v-layout>
</template>

<script>
/* eslint-disable */
export default {
  data: () => ({
    dialog: false,
    data: null,
    dataDT: [],
    // kitsPcr: [],
    kitsPcr: [
      {
        id: 1,
        name: 'Steriplex Bacterias',
        id_ensayos: 994, // id ensayos [idali, idsuper, idmnip,idagua,idamb]
      },
      {
        id: 2,
        name: 'Steriplex hongos y levaduras',
        id_ensayos: 998, // id ensayos [idali, idsuper, idmnip,idagua,idamb]
      },
    ],
    pocillos: [
      {
        id: 1, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 2, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 3, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 4, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 5, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 6, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 7, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 8, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 9, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 10, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 11, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 12, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 13, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 14, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 15, data: null, analisis: null, checked: false, nameCheck: '',
      },
      {
        id: 16, data: null, analisis: null, checked: false, nameCheck: '',
      },
    ],
    desserts: [],
    selectedSample: null,
    controles: { cp: false, cn: false },
    actions: [],
    F: { dataForm: {} },
    equipo:1,
  }),
  computed: {
    OT() {
      let response = '';
      if (this.data) {
        response = this.data.id;
      }
      return response;
    },
    OTNSamples() {
      let response = '';
      if (this.data) {
        response = this.data.muestras_ots.length;
      }
      return response;
    },
    controlSelected() {
      let selected = null;
      if (this.controles.cp) {
        selected = 'cp';
      } else if (this.controles.cn) {
        selected = 'cn';
      }
      return selected;
    },
  },
  methods: {
    /* eslint-disable no-param-reassign */
    /* eslint-disable arrow-body-style */
    /* eslint-disable array-callback-return  */
    /* eslint-disable brace-style  */

    clickSample(item) {
      this.data.muestras_ots.map((muestra) => {
        if (muestra.id !== item.id && muestra.vmodel === true) {
          muestra.vmodel = false;
        }
        return false;
      });
      if (item.vmodel === true) {
        this.selectedSample = item;
      } else {
        this.selectedSample = null;
      }
    },
    clickPocillo(pocillo) {
      // LIGA EL POCILLO CON LA MUESTRA
      // console.log('click pocillo*************');
      if (this.selectedSample && this.controlSelected === null && pocillo.data === null) {
        this.saveAction(pocillo);
        pocillo.data = this.selectedSample.id;
        pocillo.muestra = this.selectedSample;

        // DESHABILITA LA MUESTRA Y SELECCIONA LA SIGUIENTE
        let selected = null;
        this.data.muestras_ots.map((muestra) => {
          if (
            !selected && muestra.disabled === false
            && muestra.id !== this.selectedSample.id
            && muestra.visible === true
          ) {
            muestra.vmodel = true;
            selected = muestra;
          } else if (muestra.id === this.selectedSample.id) {
            muestra.disabled = true;
            muestra.vmodel = false;
          }
        });

        this.selectedSample = selected;
      } else if (this.controlSelected !== null && pocillo.data === null) {
        this.saveAction(pocillo);
        pocillo.data = this.controlSelected.toUpperCase();
      } else if (this.controlSelected === null && this.selectedSample === null) {
        setTimeout(() => { pocillo.checked = false; }, 100);
      }
      // SELECTED DEL POCILLO
      // console.log('selected del pocillo');
      if (pocillo.data !== null) {
        setTimeout(() => { pocillo.checked = true; }, 100);
      }
    },
    checkCotrol(control, value) {
      if (value) {
        switch (control) {
          case 'cp': default: this.controles.cn = false; break;
          case 'cn': this.controles.cp = false; break;
        }
      }
    },
    saveAction(pocillo) {
      const poc = JSON.parse(JSON.stringify(pocillo));
      const obj = {
        pocillo: poc.id,
        // eslint-disable-next-line
        newValue: (this.controlSelected) ? this.controlSelected.toUpperCase() : this.selectedSample.id,
        lastValue: poc.data,
      };
      this.actions.push(obj);
      return obj;
    },
    deshacer() {
      // console.log('deshacer');
      if (this.actions.length > 0) {
        // VERIFICAR SI ES UNA MUESTRA PARA HABILITAR LA MUESTRA DE NUEVO
        const last = this.actions[this.actions.length - 1];
        // eslint-disable-next-line
        if (!isNaN(parseFloat(last.newValue))) {
          this.data.muestras_ots.map((muestra) => {
            muestra.vmodel = false;
            if (muestra.disabled === true && muestra.id === last.newValue) {
              muestra.disabled = false;
              muestra.vmodel = true;
              this.selectedSample = muestra;
            }
          });
        }
        // ELIMINAR VALOR DEL POCILLO
        this.pocillos.map((pocillo) => {
          if (pocillo.id === last.pocillo) {
            pocillo.data = null;
            pocillo.checked = false;
            pocillo.muestra = null;
            pocillo.nameCheck =  '';
          }
        });
        // ELIMINAR ULTIMO REGISTRO DE LAS ACCIONES
        this.actions.splice(this.actions.length - 1, 1);
      }
    },
    filtraEnsayos() {
      this.limpiaPocillos();
      this.limpiarMuestras();
      this.actions = [];
    },
    limpiaPocillos() {
      this.pocillos.map((pocillo) => {
        pocillo.data = null;
        pocillo.checked = false;
        pocillo.muestra = null;
        pocillo.nameCheck = '';
      });
    },
    limpiarMuestras() {
      const items = [];
      this.data.muestras_ots.map((muestra) => {
        muestra.vmodel = false;
        muestra.disabled = false;
        muestra.visible = false;
        if (this.F.dataForm.kitPcr) {
          const kitpcr = this.F.kitPCR.filter((kit) => kit.id === this.F.dataForm.kitPcr)[0];
          // kitpcr = kitpcr[0];
          let found = false;
          muestra.ensayo_muestras.map((ensayoMuestra) => {
            kitpcr.id_ensayos.map((kitEnsayo) => {
              if (kitEnsayo === ensayoMuestra.ensayo_id) {
                found = true;
              }
            });
          });
          if (found) {
            items.push(muestra);
            muestra.visible = true;
            if (items.length === 1) {
              muestra.vmodel = true;
              this.selectedSample = muestra;
            }
          }
        }
      });
    },
    //
    open(data, F) {
      this.dialog = true;
      this.limpiaPocillos();
      // console.log('open', [data, F]);
      data.muestras_ots.map((muestra) => {
        muestra.vmodel = false;
        muestra.disabled = false;
        muestra.visible = false;
      });
      this.controles.cp = false;
      this.controles.cn = false;

      this.F = F;
      this.data = data;
      this.equipo = this.F.dataForm.equipoKit;
      this.generarPosillos(this.F.dataForm.equipoKit);
      this.limpiarMuestras();
      console.log('abrir pocillos', JSON.parse(JSON.stringify(this.pocillos)));
    },
    close() {
      this.dialog = false;
    },
    save() {
      const infoData = this.data;
      const newObj = [];
      this.pocillos.map((pocillo) => {
        // SI NO ES NULO
        if (pocillo.data !== null) {
          const obj = {
            pocillo:
            pocillo.id,
            muestra: null,
            control: null,
            local: infoData.local,
          };
          // SI VIENE DE UNA MUESTRA
          if (pocillo.muestra) {
            obj.muestra = pocillo.muestra;
          }
          // SI ES DE UN CONTROL
          else {
            obj.control = pocillo.data;
          }
          newObj.push(obj);
        }
      });
      // console.log('hhh',newObj);
      // this.F.local = ((infoData.local) ? infoData.local.nombre_local : '');
      this.F.analizarMuestras(newObj);
      this.dialog = false;
    },
    generarPosillos(item)
    {
      console.log("kitpcr = "+item);
      let posi = 16;
      let abc = [''];
      let j = 1;
      if (item == 2)
      {
        posi = 12;
        abc = ['A','B','C','D','E','F','G','H']
      }
      const posiArray = [];
      abc.forEach(function (index) {
        for (let i = 1; i <= posi; i++) {
          const filaposillo = {
            id: j, data: null, analisis: null, checked: false, nameCheck: ( (abc.length>1) ? index+i : ''),
          };
          posiArray.push(filaposillo);
          j++;
        }
      });
      this.pocillos = posiArray;
    },
    getEquipo(){
      let clase = this.equipo==1 ? 'o-pocillos' : 'o-pocillos-12';
      console.log(['equipo',this.equipo,clase])
      return clase;
    },
  },
};
</script>
